import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_en_frontendmdx = graphql`
        query PageQuery_service_en_frontendmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/frontend/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Front-end",
  "path": "/service/frontend",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "en",
  "icon": "bx-user-pin",
  "tags": ["developer", "medior", "senior"],
  "experience": [{
    "title": "Website Freelancer",
    "task": "Developer",
    "tags": ["developer"],
    "outcome": ["Multi-language content", "Future-proof and extendable", "Server-side content rendering"],
    "link": ["https://www.cheesebyte.nl"],
    "image": ["./images/frontend/cb-site-01.png", "./images/frontend/cb-site-02.png", "./images/frontend/cb-site-03.png"],
    "copyright": "Cheesebyte"
  }, {
    "title": "Racing Tracker",
    "task": "Developer",
    "tags": ["developer", "second lead"],
    "outcome": ["Prediction & improvement of performance", "Complex components to visualise stats", "Real-time (visual) player tracking", "Bidirectional data streams"],
    "link": null,
    "copyright": null
  }, {
    "title": "3D Patient Rehabilitation",
    "task": "Lead Front-end / Pipeline",
    "tags": ["lead", "developer", "unity3d"],
    "outcome": ["Apps for Android and iOS", "Apps for macOS and Windows", "Custom GUI with uGUI", "Internal apps"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Time Registration",
    "task": "Main Developer",
    "tags": ["developer"],
    "outcome": ["Export & secure storage", "Automatic synchronisation", "Desktop UI & background service", "Continuous registration of hours", "Reduced cost in handling hours"],
    "link": null,
    "copyright": null
  }]
};
const layoutProps = {
  PageQuery_service_en_frontendmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "pixel-perfect-ui",
      "style": {
        "position": "relative"
      }
    }}>{`Pixel-perfect UI`}<a parentName="h1" {...{
        "href": "#pixel-perfect-ui",
        "aria-label": "pixel perfect ui permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`Vector, pixel or 9-patch? Yes to all of these questions!`}</p>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}>{`Skills`}<a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`5+ years web UI experience`}</li>
      <li parentName="ul">{`10+ years experience with UI in general`}</li>
      <li parentName="ul">{`Advocate of component-based design`}</li>
      <li parentName="ul">{`Knowledge about common UX / UI patterns`}</li>
    </ul>
    <h2 {...{
      "id": "technical",
      "style": {
        "position": "relative"
      }
    }}>{`Technical`}<a parentName="h2" {...{
        "href": "#technical",
        "aria-label": "technical permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Desktop: Win32, MFC, Qt, Gtk+`}</li>
      <li parentName="ul">{`Web: HTML, ES6+, CSS, SASS, React, Blazor`}</li>
      <li parentName="ul">{`Custom-built UI (Unity 3D)`}</li>
      <li parentName="ul">{`Static sites via SSR, DSG (Gatsby, Next.js)`}</li>
      <li parentName="ul">{`Experienced with internationalisation (i18n)`}</li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      